/* eslint-disable max-len */
window.translations = {
  account: 'Omat tiedot',
  ageGroupShorterTrip: 'Ikäryhmällä {{ageGroups}} on lyhennetty matka',
  ageGroupsShorterTrip: 'Ikäryhmillä {{ageGroups}} on lyhennetty matka',
  allAnnouncements: 'Kaikki tiedotteet',
  allCompetitors: 'Kaikki kilpailijat',
  allClubs: 'Kaikki seurat',
  allDistricts: 'Kaikki piirit',
  allLevels: 'Kaikki tasot',
  allRaces: 'Kaikki kilpailut',
  allSeries: 'Kaikki sarjat',
  allSeriesStartTimesPdf: 'Kaikkien sarjojen lähtöajat (PDF)',
  allSports: 'Kaikki lajit',
  alsoCompetitorsFromClubs: 'Lisäksi kaikki kilpailijat seurasta (paina ctrl, jos haluat valita useita)',
  alsoCompetitorsFromDistricts: 'Lisäksi kaikki kilpailijat piiristä (paina ctrl, jos haluat valita useita)',
  announcements: 'Tiedotteet',
  answersTitle: 'Kysymyksiä ja vastauksia',
  appTitle: 'Hirviurheilu - Tulospalvelu Metsästäjäliiton urheilulajeille',
  backToCupHomePage: 'Takaisin cup-kilpailun etusivulle',
  backToHomePage: 'Takaisin etusivulle',
  backToPage: 'Takaisin sivulle {{pageName}}',
  heatList: 'Eräluettelo',
  heatListNotCreated: 'Kilpailun eräluetteloa ei ole tehty',
  heatListNotPublished: 'Eräluetteloa ei ole vielä julkaistu',
  cancelled: 'Peruttu',
  captchaInfo: 'Tällä tarkastetaan, ettet ole robotti. Oikea vastaus on siis neljä.',
  captchaQuestion: 'Paljonko on kaksi plus kaksi?',
  club: 'Seura',
  clubs: 'Seurat',
  comparisonTime: 'Vertailuaika',
  competitor: 'Kilpailija',
  competitor_DNS: 'Kilpailija ei osallistunut kilpailuun',
  competitor_DNF: 'Kilpailija keskeytti kilpailun',
  competitor_DQ: 'Kilpailija hylättiin',
  competitorsCountMany: '{{count}} kilpailijaa',
  competitorsCountOne: '1 kilpailija',
  competitorsPerSeries: 'Kilpailijoiden määrä / sarja',
  correctDistances: 'Oikeat etäisyydet',
  country: 'Maa',
  countries: 'Maat',
  cupCompetitions: 'Osakilpailut',
  cupHome: 'Cup-kilpailun etusivu',
  cupPointsRule: 'Yhteispisteet lasketaan {{bestCompetitionsCount}} parhaan tuloksen perusteella',
  cupPointsRuleTitle: 'Pistelaskenta',
  cupPointsRuleWithLast: 'Yhteispisteet lasketaan viimeisen kilpailun sekä muista kilpailuista {{bestCompetitionsCount}} parhaan tuloksen perusteella',
  cupPublicMessage: ' Cup-kilpailun järjestäjä tiedottaa',
  cups: 'Cup-kilpailut',
  district: 'Piiri',
  districts: 'Piirit',
  downloadAllResults: 'Lataa tuloskooste',
  downloadHeatList: 'Lataa eräluettelo',
  downloadHeatListPdf: 'Lataa eräluettelo PDF-tiedostona',
  downloadPressReport: 'Lataa lehdistöraportti',
  downloadRelayCompetitorsPdf: 'Lataa osallistujaluettelo PDF-tiedostona',
  downloadResultsCsv: 'Lataa tulokset CSV-tiedostona',
  downloadResultsPdf: 'Lataa tulokset PDF-tiedostona',
  downloadResultsPdfWithCompetitors: 'Lataa tulos-PDF (kilpailijoiden kanssa)',
  downloadResultsPdfWithoutCompetitors: 'Lataa tulos-PDF (ilman kilpailijoita)',
  downloadStartListPdf: 'Lataa lähtölista PDF-tiedostona',
  downloadStartTimes: 'Lataa lähtöajat',
  email: 'Sähköposti',
  estimatePenalties: 'Arviosakot',
  estimating: 'Arviointi',
  european_compak: 'Compak',
  european_rifle: 'Luodikko',
  european_rifle1: 'Metsäkauris',
  european_rifle2: 'Kettu',
  european_rifle3: 'Gemssi',
  european_rifle4: 'Villisika',
  european_shotgun: 'Haulikko',
  european_trap: 'Trap',
  extraRound: 'Uusinta',
  feedback: 'Palaute',
  feedbackRaceInfo: 'Mikäli haluat lähettää palautetta tietyn kilpailun järjestäjälle, valitse kilpailu Palautteen kohde -valikosta',
  feedbackReceiver: 'Palautteen kohde',
  feedbackReceiverCommon: 'Yleinen Hirviurheilu-palaute',
  feedbackSentInfo: 'Jos jätit yhteystietosi, otamme sinuun yhteyttä mahdollisimman pian',
  feedbackThankYou: 'Kiitos palautteesta',
  finalRound: 'Loppukilpailu',
  finalRoundHeatList: 'Loppukilpailun eräluettelo',
  finish: 'Maali',
  hideCompetitors: 'Piilota kilpailijat',
  hideCupCompetitions: 'Piilota osakilpailut',
  hideShots: 'Piilota laukaukset',
  homePage: 'Etusivu',
  info: 'Tietoa Hirviurheilusta',
  latestAnnouncements: 'Uusimmat tiedotteet',
  leg: 'Osuus',
  legNumber: 'Osuus {{leg}}',
  legTime: 'Osuusaika',
  level_0: 'Muu',
  level_1: 'Piiri',
  level_2: 'Alue',
  level_3: 'SM',
  level_4: 'Kansainvälinen',
  name: 'Nimi',
  navigate: 'Sijainti kartalla',
  noCompetitors: 'Ei kilpailijoita',
  noCompetitorsOrStartTimes: 'Tähän sarjaan ei ole lisätty kilpailijoita tai heille ei ole määritetty vielä lähtöaikoja.',
  noCupSeries: 'Cup-sarjoja ei ole vielä määritetty',
  nordic_rifle_moving: 'Hirvi',
  nordic_rifle_standing: 'Kauris',
  nordic_shotgun: 'Compak',
  nordic_trap: 'Trap',
  noTeams: 'Ei joukkueita',
  noTimeLimit: 'Tulevat ja päättyneet',
  numberShort: 'Nro',
  officialHomePage: 'Toimitsijan sivut',
  pdfPageBreaks: 'Sivunvaihdot sarjojen väliin',
  personalCompetitions: 'Yksilökilpailut',
  points: 'Pisteet',
  press: 'Lehdistö',
  pressInfoUnfinishedRace: 'Tältä sivulta voit ladata lehdistöraportin, kun kilpailu on päättynyt',
  pressReportInstructions: 'Maalaa alla oleva tulosraportti ja kopioi se esimerkiksi sähköpostiisi tai vaikkapa tekstinkäsittelyohjelmaan jatkokäsittelyä varten',
  prices: 'Hinnat',
  qAndA: 'Kysymyksiä ja vastauksia',
  qualificationRound: 'Alkukilpailu',
  qualificationRoundHeatList: 'Alkukilpailun eräluettelo',
  raceCancelledMessage: 'Valitettavasti kilpailu on peruttu',
  raceCancelledTitle: 'Kilpailu peruttu',
  raceHomePage: 'Kilpailun kotisivut',
  raceMenuHome: 'Kilpailun etusivu',
  raceOrganizer: 'Kilpailun järjestäjä',
  racePublicMessage: 'Kilpailun järjestäjä tiedottaa',
  raceResultsPdf: 'Tuloskooste (PDF)',
  raceStarted: 'Kilpailu alkoi {{time}}',
  raceStarts: 'Kilpailu alkaa {{time}}',
  races: 'Kilpailut',
  races_future: 'Tulevat kilpailut',
  races_past: 'Päättyneet kilpailut',
  races_today: 'Kilpailut tänään',
  races_yesterday: 'Eilen päättyneet kilpailut',
  races_tomorrow: 'Kilpailut huomenna',
  races_day_after_tomorrow: 'Kilpailut ylihuomenna',
  races_this_week: 'Kilpailut tällä viikolla',
  races_next_week: 'Kilpailut ensi viikolla',
  races_this_month: 'Kilpailut myöhemmin tässä kuussa',
  races_next_month: 'Kilpailut ensi kuussa',
  races_later: 'Kilpailuja tulossa myöhemmin',
  races_previously: 'Aiemmin päättyneet kilpailut',
  races_latest: 'Viimeksi päättyneet kilpailut',
  racesNotFound: 'Kilpailuja ei löytynyt',
  raceSearchPlaceholder: 'Kilpailun nimi tai sijainti',
  raceUnfinished: 'Kilpailu on kesken',
  raceUnfinishedDistancesLater: 'Kilpailu on kesken. Tarkemmat arviointitiedot julkaistaan, kun kilpailu on päättynyt.',
  raceWithoutSeries: 'Kilpailuun ei ole vielä lisätty sarjoja',
  relay: 'Viesti',
  relayNotStarted: 'Viesti ei ole vielä alkanut',
  relayPenaltySeconds_RUN: 'Sakkoja ei juosta vaan ne lisätään aikaan. Arviointi: {{estimatePenaltySeconds}} s/sakko. Ammunta: {{shootingPenaltySeconds}} s/sakko.',
  relayPenaltySeconds_SKI: 'Sakkoja ei hiihdetä vaan ne lisätään aikaan. Arviointi: {{estimatePenaltySeconds}} s/sakko. Ammunta: {{shootingPenaltySeconds}} s/sakko.',
  relays: 'Viestit',
  relayStartTime: 'Viestin lähtöaika: {{time}}',
  relayWithoutStartTime: 'Viestin lähtöaikaa ei ole vielä päätetty',
  relayWithoutTeams: 'Viestiin ei ole vielä lisätty joukkueita',
  reset: 'Tyhjennä',
  result: 'Tulos',
  resultRotation: 'Tuloskehä',
  resultRotationInfo: 'Valitse vähintään kaksi sarjaa ja käynnistä tuloskehä. Sarjat vaihtuvat automaattisesti {{seconds}} sekunnin välein. Kun haluat sammuttaa tuloskehän, palaa takaisin tälle sivulle.',
  resultRotationStart: 'Käynnistä tuloskehä',
  resultRotationStop: 'Sammuta tuloskehä',
  results: 'Tulokset',
  resultsInProgress: 'Tulokset päivitetty {{time}}',
  rifle: 'Luodikko',
  rifleResults: 'Luodikon tulokset',
  rifleTeamCompetition: 'Luodikon joukkuekilpailu',
  rifleTeamCompetitions: 'Luodikon joukkuekilpailut',
  search: 'Etsi',
  searchRace: 'Etsi kilpailu',
  secondsPerPage: 'Sekuntia / sivu',
  send: 'Lähetä',
  sendFeedback: 'Lähetä palautetta',
  series: 'Sarja',
  seriesPlural: 'Sarjat',
  seriesShorterTrip: 'Sarjalla on lyhennetty matka',
  seriesNoCompetitors: 'Sarjaan ei ole lisätty kilpailijoita',
  seriesNoStartList: 'Sarjan lähtöluetteloa ei ole vielä julkaistu',
  seriesNotStarted: 'Sarja ei ole vielä alkanut',
  seriesPointsMethod1_SKI: 'Sarjassa on 4 arviota, arviopisteet -1p/1m',
  seriesPointsMethod1_RUN: 'Sarjassa on 4 arviota, arviopisteet -1p/1m',
  seriesPointsMethod2_SKI: 'Hirvenhiihtely (sarjassa on 4 arviota pistein -2p/1m, ei aikapisteitä)',
  seriesPointsMethod2_RUN: 'Hirvikävely (sarjassa on 4 arviota pistein -2p/1m, ei aikapisteitä)',
  seriesPointsMethod3_SKI: 'Hirvenhiihtely (sarjassa on 2 arviota, kaikki saavat 300 aikapistettä)',
  seriesPointsMethod3_RUN: 'Hirvikävely (sarjassa on 2 arviota, kaikki saavat 300 aikapistettä)',
  seriesPointsMethod4_SKI: 'Hirvenhiihtely (sarjassa on 2 arviota pistein -4p/1m, ei aikapisteitä)',
  seriesPointsMethod4_RUN: 'Hirvikävely (sarjassa on 2 arviota pistein -4p/1m, ei aikapisteitä)',
  seriesStartTime: 'Sarjan lähtöaika',
  shooting: 'Ammunta',
  shootingPenalties: 'Ammuntasakot',
  shootingRulesPenalty: 'Rangaistus ammuntasääntöjen rikkomisesta',
  shootingTime: 'Ammunta-aika',
  shots: 'Laukaukset',
  showAll: 'Näytä kaikki',
  showCompetitors: 'Näytä kilpailijat',
  showCupCompetitions: 'Näytä osakilpailut',
  showOfficialResults: 'Näytä viralliset tulokset',
  showShots: 'Näytä laukaukset',
  showUnofficialResults: 'Näytä epäviralliset tulokset (kaikki kilpailijat mukana)',
  sport_EUROPEAN: 'Eurooppalainen metsästysammunta',
  sport_ILMAHIRVI: 'Ilmahirvi',
  sport_ILMALUODIKKO: 'Ilmaluodikko',
  sport_METSASTYSHAULIKKO: 'Metsästyshaulikko',
  sport_METSASTYSHIRVI: 'Metsästyshirvi',
  sport_METSASTYSLUODIKKO: 'Metsästysluodikko',
  sport_METSASTYSTRAP: 'Metsästystrap',
  sport_NORDIC: 'Pohjoismainen metsästysammunta',
  sport_PIENOISHIRVI: 'Pienoishirvi',
  sport_PIENOISLUODIKKO: 'Pienoisluodikko',
  sport_RUN: 'Hirvenjuoksu',
  sport_SKI: 'Hirvenhiihto',
  sportsInfo: 'Tietoa lajeista',
  startList: 'Lähtölista',
  startLists: 'Lähtölistat',
  startTime: 'Lähtöaika',
  startUsage: 'Aloita käyttö',
  team: 'Joukkue',
  team_DNS: 'Joukkue ei osallistunut kilpailuun',
  team_DNF: 'Joukkue keskeytti kilpailun',
  team_DQ: 'Joukkue hylättiin',
  teamCompetition: 'Joukkuekilpailu',
  teamCompetitions: 'Joukkuekilpailut',
  teamCompetitionResultsNotAvailable: 'Joukkuekilpailun tuloksia ei voida vielä laskea',
  tel: 'Puhelin',
  timeTitle_RUN: 'Juoksu',
  timeTitle_SKI: 'Hiihto',
  totalPoints: 'Yhteispisteet',
  totalResults: 'Yhteistulokset',
  track: 'Rata',
  unofficialCompetitor: 'Epävirallinen kilpailija',
  unofficialShort: 'epäv.',
  useQualificationRoundResult: 'Pisteet lasketaan alkukilpailun tuloksista',
}
